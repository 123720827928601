.contactsWidget {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    bottom: 200px;
    left: 65px;
    z-index: 10;
    color: #1d1d1b;
}

.widgetWindowClose {
    z-index: -1;
    display: none;
    opacity: 0;
    transition: 0s ease;
}

.widgetWindow {
    position: fixed;
    max-width: 275px;
    bottom: 275px;
    left: 65px;
    display: block;
    background: #ffffff;
    opacity: 1;
    transition: 0.3s ease;
    box-shadow: 0 0 20px 0 rgba(0,0,0,.3);
    padding: 30px 20px 20px;
    border-radius: 5px;
    text-align: center;
}

@media screen and (max-width: 1479px) {
    .contactsWidget {
        left: 15px;
    }
    .widgetWindow {
        left: 10px;
        max-width: 220px;
    }
}

@media screen and (max-width: 767px) {
    .contactsWidget {
        bottom: 22px;
    }
    .widgetWindow {
        bottom: 150px;
    }
}

.widgetButton {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #00843e;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s ease;
    box-shadow: 0 0 20px 0 rgba(0,0,0,.3);
}

.widgetButtonOpen {
    background: #ffffff;
    transition: 0.3s ease;
}

.widgetButton img {
    width: 30px;
}

.widgetTitle {
    font-size: 24px;
    font-weight: 500;
    padding-bottom: 30px;
}

.widgetElementsWrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.navbar {
    display: flex;
}

.navbarItem {
    padding: 0 15px;
    display: flex;
    height: 88px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid transparent;
    transition: 0.3s ease;
    background: #ffffff;
}

.navbarDrop {
    cursor: pointer;
    position: relative;
    display: inline-block;
}

.navbarDrop:hover .navbarDropItem {
    display: flex;
}
.navbarDropItem {
    border: 1px solid #00843e;
    display: none;
    position: absolute;
    z-index: 25;
    bottom: -90px;
    left: 0;
    right: 0;
}

.navbarDropItem:last-child {
    bottom: -180px;
}

.navbarItem:hover {
    border: 1px solid #00843e;
    color: #00843e;
}

@media screen and (max-width: 767px) {
    .navbar {
        display: none;
    }
    .navbarOpen {
        display: flex;
        flex-direction: column;
        font-size: 1.25rem;
        gap: 20px;
        margin-bottom: 30px;
    }
    .navbarItem {
        height: auto;
    }
}


@media screen and (max-width: 1023px) {
    .navbarItem {
        padding: 0 10px;
    }
}

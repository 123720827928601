.formBlock {
    background: #f5f5f5;
    padding: 50px 0;
}

.formTitle h2 {
    margin-top: 0;
}

.formSubtitle {
    font-weight: 300;
    text-align: left;
    font-size: 1.5rem;
    color: #1d1d1b;
    margin: 0;
    padding-bottom: 50px;
}

.formRow {
    padding: 0 0 20px;
    display: flex;
    justify-content: space-between;
}

.label {
    max-width: 21%;
    width: 100%;
    display: flex;
    position: relative;
}

.input {
    max-width: 100%;
    width: 100%;
    border: 1px solid #e0e0e0;
}

@media screen and (max-width: 899px) {
    .form .formLabel {
        width: 20%;
    }
}

.formSubmit {
    color: #ffffff;
    background: #00843e;
    padding: 20px 0;
    transition: 0.3s ease;
    cursor: pointer;
    font-size: 1rem;
    white-space: normal;
}

.formSubmit:hover {
    background: #009b47;
}

.formBLockText {
    font-size: 0.875rem;
    text-align: left;
    letter-spacing: 0.1em;
}

@media screen and (max-width: 767px) {
    .formRow {
        flex-direction: column;
    }
    .label {
        max-width: 100%;
        width: 100%;
    }
    .input {
        max-width: 100%;
        width: 100%;
        height: 60px;
        margin-bottom: 20px;
    }
    .formBLockText, .formSubtitle {
        text-align: center;
    }
}
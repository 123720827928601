.tariffsPopup {
    z-index: 20;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    width: 100%;
    max-height: 80vh;
    min-height: 80vh;
    padding: 1rem;
}

.tariffsPopupHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tariffsPopupInfo {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
}

.greenText {
    color: #00843e;
}

@media screen and (max-width: 1250px) {
    .tariffsPopup {
        width: 90%;
    }
}

.tariffsButton {
    background: #00843e;
    transition: 0.3s ease;
    border: none;
    cursor: pointer;
    margin-bottom: 1rem;
    padding: 10px;
    color: #ffffff;
    display: flex;
    width: 290px;
    justify-content: center;
    font-size: 1rem;
}

.tariffsButton:hover {
    background: #009b47;
}

.tariffsTable {
    width: 100%;
    overflow-y: scroll;
    border-spacing: 0;
    border: 3px solid #00843e;
    border-collapse: collapse;
}

th, td {
    padding: 30px 20px;
    border: 3px solid #00843e;
    border-collapse: collapse;
}

th {
    font-size: 1.25rem;
}

td {
    font-size: 1.125rem;
}

.popupParagraph {
    margin-bottom: 0;
}

@media screen and (max-width: 660px) {
    .tariffsPopupHeader {
        flex-direction: column;
    }
    .tariffsPopup {
        width: 92%;
        top: 0;
        left: 0;
        height: 100vh;
        max-height: 100vh;
        transform: none;
    }
    .tariffsButton {
        width: 100%;
        padding: 20px;
    }
    .tableWrapper {
        overflow-x: scroll;
    }
    .tariffsTable {
        overflow-x: scroll;
    }
    th, td {
        padding: 10px 5px;
    }
}
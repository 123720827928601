.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.App-no-scroll {
  overflow: hidden;
}

main {
  margin-top: 90px;
}
.AppContent {
  flex: 1 0 auto;
}
.calcOverlay {
    z-index: 19;
    position: fixed;
    background: rgba(0,0,0,.8);
    width: 100%;
    height: 101%;
    overflow: hidden;
    top: 0;
    left: 0;
}

.calcOverlayButton {
    cursor: pointer;
}

.articleTitle {
    margin-top: 0;
    font-size: 2.25rem;
    color: #1d1d1b;
    text-align: left;
    font-weight: 400;
    margin-bottom: 30px;
    line-height: 1;
}

.calcForm {
    border: #00843e 1px solid;
    border-radius: 4px;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
    text-align: left;
    color: #1d1d1b;
}

.calcHorizontalGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.calcSwitchBlock {
    width: 60px;
}

.calcSwitchBlock img {
    width: 100%;
    cursor: pointer;
}

.calcLabel {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 38%;
    text-align: left;
}

.calcLongInput {
    width: 100%;
    margin: 10px 0;
    outline: none;
    border: 1px solid #1d1d1b;
}

.calcHelp {
    color: #7e7e7e;
    font-size: 0.75rem;
}

.calcError {
    color: red;
    font-size: 0.75rem;
    font-weight: bold;
}

.calcShortHorizontalGroup {
    gap: 20px;
}

.calcShortLabel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 0.875rem;
    color: #7e7e7e;
    width: 100%;
    letter-spacing: 0.05rem;
}

.calcShortInput {
    outline: none;
    border: 1px solid #1d1d1b;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
}

.calcSubmitGroup {
    display: flex;
    align-items: center;
    gap: 20px;
}

.calcSubmit {
    width: 40%;
}

.searchResultsWrapper {
    position: relative;
    width: 100%;
}

.searchResults {
    position: absolute;
    z-index: 2;
    background: #ffffff;
    width: 100%;
}


@media screen and (max-width: 767px) {
    .calcHorizontalGroup {
        flex-direction: column;
    }
    .calcLabel {
        width: 100%;
    }
    .calcSwitchBlock img {
        transform: rotate(-90deg);
    }
    .calcShortBlock {
        width: 100%;
    }
    .calcSubmitGroup {
        flex-direction: column;
        justify-content: center;
        gap: 10px;
    }
    .calcSubmit {
        width: 100%;
    }
}
.modal {
    background: #ffffff;
    left: 50%;
    top: 50%;
    position: fixed;
    transform: translate(-50%, -50%);
    width: 550px;
    min-height: 550px;
    z-index: 12;
    padding: 30px 40px 40px 40px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.closeBlock {
    display: flex;
    justify-content: right;
}

.close {
    width: 40px;
    height: 40px;
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
}

.close img {
    width: 100%;
}

.title {
    text-transform: uppercase;
}

.p {
    text-align: justify;
}

.buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 650px){
    .modal {
        width: 70%;
        min-height: 300px;
        padding: 20px;
        max-height: 500px;
    }
    .p {
        text-align: left;
        overflow-y: auto;
        max-height: 250px;
    }
    .buttons {
        flex-direction: column;
    }
    .button {
        width: 100%;
    }
    .button:not(:last-child) {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 300px){
    .p {
        max-height: 220px;
    }
}

.button {
    text-transform: uppercase;
    border-radius: 30px;
    padding: 15px 20px;
    font-size: 16px;
    font-family: Roboto;
    cursor: pointer;
    transition: 0.3s ease;
    box-sizing: border-box;
    text-align: center;
}

.queryButton {
    background: #18B458;
    border: 2px solid transparent;
    color: white;
}

.queryButton:hover {
    background: white;
    color: #000000;
    border: 2px solid #18B458;
}

.questionnaireButton {
    background: white;
    color: #000000;
    border: 2px solid #18B458;
}

.questionnaireButton:hover {
    background: #18B458;
    border: 2px solid transparent;
    color: white;
}
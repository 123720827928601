.contactsWidgetElement {
    width: 27%;
}

.contactsWidgetElement:not(:last-child) {
    margin-bottom: 10px;
}

.contactsWidgetElement img {
    width: 100%;
}

.contactName {
    font-size: 0.75rem;
    padding-top: 10px;
}
.footer {
    background: #00843e;
    color: #ffffff;
    min-height: 100px;
    padding: 30px 0;
    flex: 0 0 auto;
}

.footerRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footerHr {
    margin-top: 40px;
    margin-bottom: 10px;
    border-color: #ffffff;
}

.footerSocialLinks {
    display: flex;
}

.footerSocialLink {
    width: 45px;
    display: flex;
    border: 2px solid #ffffff;
    border-radius: 50%;
}

.footerSocialLink:nth-child(2) {
    margin: 0 10px;
}

.footerSocialLink img {
    width: 100%;
}

@media screen and (max-width: 767px) {
    .footerRow {
        flex-direction: column;
    }
}


.footerRowText {
    color: #ffffff;
    display: block;
}

.footerRowText:not(:last-child) {
    margin-bottom: 10px;
}

@media screen and (max-width: 1023px) {
    .footerRowRight {
        margin-right: 7%;
    }
}

@media screen and (max-width: 767px) {
    .footerRowCenter {
        margin-left: 0;
    }
    .footerRowRight {
        margin-right: 0;
    }
    .footerSocialLink {
        width: 64px;
    }
    .footerSocialLink:nth-child(2) {
        margin: 0 15px;
    }
    .footerRow {
        gap: 40px;
    }
    .footerCol {
        text-align: center !important;
        width: 100% !important;
        align-items: center;
    }
}

.footerRowYear {
    margin-top: 10px;
}

.footerCol {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 30%;
    font-size: 20px;
}

.footerLink {
    color: #ffffff;
}

.footerLink:hover {
    opacity: 0.5;
    color: #ffffff;
}

.footerCol:nth-child(2) {
    text-align: center;
}

.footerCol:last-child {
    text-align: right;
}

.footerLink:not(:last-child) {
    margin-bottom: 10px;
}
.articleTitle {
    font-size: 2.25rem;
    color: #1d1d1b;
    text-align: left;
    font-weight: 400;
    margin-bottom: 30px;
}

@media screen and (max-width: 414px) {
    .articleTitle {
        text-align: center;
    }
}
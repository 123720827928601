.section {
    padding: 20px 0;
    min-height: 40vh;
}

.title {
    font-size: 5rem;
    margin-top: 0;
    color: #00843e;
    margin-bottom: 2rem;
}

.p {
    font-size: 24px;
    margin: 0;
}

.p a {
    color: #00843e;
}

.p a:hover {
    color: #009b47;
}
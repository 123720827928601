.offerItem {
    width: 24%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
    cursor: pointer;
}

.offerItemImage {
    width: 30%;
    transition: 0.6s ease;
    user-drag: none; /* Запрет на перетаскивание */
    user-select: none; /* Запрет на выделение текста (это необходимо, если вы не хотите, чтобы изображение также блокировало выделение текста на странице) */
    -moz-user-select: none; /* Для поддержки в Firefox */
    -webkit-user-drag: none; /* Для поддержки в старых версиях Chrome и Safari */
    -webkit-user-select: none; /* Для поддержки в старых версиях Chrome и Safari */
}

.offerItem:hover .offerItemImage {
    transform: scale(1.1);
}

@media screen and (max-width: 767px) {
    .offerItem {
        width: 49%;
    }
    .offerItemImage {
        width: 20%;
    }
}

@media screen and (max-width: 414px) {
    .offerItem {
        width: 100%;
    }
    .offerItem:last-child {
        padding-bottom: 0;
    }
}

.offerItemTitle {
    margin: 0;
    padding: 10px 0;
}

.offerItemText {
    font-size: 0.875rem;
}
html, body, #root {
    height: 100%;
}

body {
    font-family: "Roboto", sans-serif;
    margin: 0;
}

input, textarea {
    border-radius: 4px;
    padding: 20px;
    color: #1d1d1b;
    font-size: 1rem;
    box-sizing: border-box;
}

input:focus, textarea:focus {
    outline: none;
}

input[type="date" i] {
    font-family: Roboto, sans-serif;
}

.container {
    max-width: 1200px;
    overflow-x: hidden;
    margin: 0 auto;
}

.section {
    padding: 20px 0;
}

@media screen and (max-width: 1220px) {
    .container {
        max-width: 100%;
        padding: 0 10px;
    }
}

a {
    text-decoration: none;
    color: #1d1d1b;
    transition: 0.3s ease;
}

a:hover {
    color: #009b47;
    transition: 0.3s ease;
}

::placeholder {
    color: #9f9f9f;
    font-size: 0.875rem;
}

.articleTitle {
    margin-top: 0;
    font-size: 2.25rem;
    color: #1d1d1b;
    text-align: center;
    font-weight: 400;
    margin-bottom: 30px;
    line-height: 1;
}

.verticalForm {
    margin: 0 auto;
    width: 69%;
}

.verticalLabel {
    color: #1d1d1b;
    text-align: left;
    display: flex;
    margin-bottom: 10px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
    font-size: 1.125rem;
    letter-spacing: 0.05rem;
    position: relative;
}

.verticalSmall {
    font-size: 0.875rem;
    margin-top: 5px;
}

.verticalInputBlock {
    margin-bottom: 30px;
}

.verticalInput, .verticalTextarea {
    width: 100%;
    border: 1px solid #1d1d1b;
}

.verticalTextarea {
    font-family: Roboto, sans-serif;
    resize: vertical;
    min-height: 120px;
}

.verticalSubmit {
    background: #00843e;
    width: 100%;
    color: #ffffff;
    transition: 0.3s ease;
    border: 0;
    outline: none;
    cursor: pointer;
    height: 60px;
}

.verticalSubmit:hover {
    background: #009b47;
}

.inputsGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
}

.custom-radio, .custom-checkboxes {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
}

.custom-radio>input, .custom-checkboxes>input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    width: 0 !important;
}

.custom-radio>.custom-radio-span::before, .custom-checkboxes>.custom-checkboxes-span::before {
    content: '';
    display: inline-block;
    width: 1.3em;
    height: 1.3em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #1d1d1b;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}

.custom-radio>.custom-radio-span::before {
    border-radius: 100%;
}

.custom-radio>input:checked+.custom-radio-span::before, .custom-checkboxes>input:checked+.custom-checkboxes-span::before {
    border-color: transparent;
    opacity: 1;
    background-color: #00843e;
    background-position: center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-radio:not(:last-child), .custom-checkboxes:not(:last-child) {
    margin-bottom: 10px;
}

.horizontalFormSpan {
    padding-bottom: 2px;
}

.visibleVerticalInput {
    display: block;
}

.hiddenVerticalInput {
    display: none;
}

.errorMessage {
    margin: 1px 0;
    position: absolute;
    font-size: 0.875rem;
    color: red;
}

.errorMessage-2 {
    position: absolute;
    font-size: 0.875rem;
    color: red;
    white-space: nowrap;
    bottom: -30px;
}

input[type="submit"]:disabled {
    pointer-events: none;
    opacity: 0.6;
}

.formBlockLink {
    color: #00843e;
    transition: 0.3s ease;
}

.formBlockLink:hover {
    color: #009b47;
}

@media screen and (max-width: 767px) {
    .verticalForm {
        width: 100%;
    }
    .verticalSmall {
        margin-left: 0;
    }
    .errorMessage-2 {
        bottom: -10px;
    }

}
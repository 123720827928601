.contractParagraph {
    margin-top: 30px;
    margin-bottom: 0;
}

.contractParagraph a {
    color: #00843e;
}

.checkboxBlock {
    margin-bottom: 30px;
}
.banner {
    max-width: 1420px;
    height: 570px;
    margin: 0 auto;
    background: no-repeat center center;
    background-size: cover;
    text-align: left;
}

.bannerWrapper {
    position: relative;
    height: 100%;
}

.bannerWrapper::after {
    display: inline-block;
    height: 100%;

    vertical-align: middle;
    content: "";
}

.bannerContent {
    display: inline-block;
    vertical-align: middle;
    max-width: 565px;
}

.bannerTitle {
    color: #009b47;
    font-size: 2rem;
    margin: 0;
    font-weight: 400;
}
.bannerSubtitle {
    font-size: 1.75rem;
    color: #009b47;
    margin: 0;
    font-weight: 400;
}

.bannerText {
    margin-bottom: 20px;
    color: #1d1d1b;
    font-size: 1.5rem;
}

.bannerText p {
    margin: 20px 0;
}

.bannerTextGreen {
    color: #009b47;
}

.bannerTextRed {
    color: red;
    text-decoration-line: line-through;
    text-decoration-color: red;
    text-decoration-thickness: 1px;
}

.biggerText {
    font-size: 2rem;
}

.bannerTextNowrap {
    white-space: nowrap;
}

.bannerLink {
    background: #00843e;
    max-width: 300px;
    width: 100%;
    text-align: center;
    display: block;
    color: #ffffff;
    border-radius: 4px;
    padding: 20.5px 0;
    transition: 0.3s ease;
}

.bannerLink:hover {
    color: #ffffff;
    background: #009b47;
}

@media screen and (max-width: 765px){
    .bannerWrapper {
        background: rgba(255, 255, 255, 0.6);
    }
}

@media screen and (max-width: 414px) {
    .banner {
        height: 380px;
        text-align: center;
    }
    .bannerTitle {
        font-size: 1.5rem;
    }
    .bannerSubtitle {
        font-size: 1.25rem;
    }
    .bannerText {
        font-size: 1rem;
    }
    .bannerText p {
        margin: 10px 0;
    }
    .biggerText {
        font-size: 1.5rem;
    }
    .bannerLink {
        max-width: 100%;
    }
}


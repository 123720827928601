.calcLinkBlock {
    display: flex;
    justify-content: space-between;
    text-align: left;
    align-items: center;
}

.calcLinkText {
    font-size: 1.25rem;
    margin: 0;
    width: 68%;
}

.calcLink {
    background: #00843e;
    max-width: 300px;
    width: 100%;
    text-align: center;
    display: block;
    color: #ffffff;
    border-radius: 4px;
    padding: 20.5px 0;
    transition: 0.3s ease;
}

.calcLink:hover {
    background: #009b47;
    color: #ffffff;
}

@media screen and (max-width: 767px) {
    .calcLinkBlock {
        flex-direction: column;
    }
    .calcLinkText {
        margin-bottom: 20px;
        text-align: center;
    }
    .calcLinkText, .calcLink {
        max-width: 100%;
    }
}